.xbo-container {
    display: flex;
    width: 80vw;
    margin: 11.25vh 0vw 0vh 19.5vw;
    height: 88.5vh;
  }

  .xbo-display-column{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 16vw;
    padding: 0.25vw;
    overflow: auto;
    margin-left: 1vw;
    font-family: 'NexaHeavy';
    background-color: black;
    opacity: 0.8;
    border-top-right-radius: 2vh;
  }

  .xbo-display-column-mb{    
  display: flex; 
  flex-direction: row;
  flex-wrap: wrap;
  background-color: black;
  width: 100%;
  height: 16.5vh;
  padding: 2px; 
  overflow: auto;
  opacity: 0.9;
  font-family: 'NexaHeavy';
  }
  
  .xbo-column {
    flex: 1;
    margin: 0vh 0vh 0vh 1vh;
    padding: 20px;
    border: 1px solid black;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    position: relative;
    height: auto;
    background-size: cover;
    background-position: center;
    opacity: 0.9;
  }
  
  .col-1 {
    background-image: url(../assets/images/batdog.jpg);
  }
  
  .col-2 {
    background-image: url(../assets/images/superdog.jpg);
  }
  
  .xbo-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }
  
  .xbo-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;    
    width: 50%;
  }
  
  .xbo-form button {
    width: 100%;
    padding: 8px;
    background-color: purple;
    color: white;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;    
    width: 25%;
  }
  
  .xbo-button {
    width: 100%;
    padding: 8px;
    color: white;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
    width: 50%;
  }
  
  .xbo-form button:hover {
    background-color: purple;
  }
  
  /* Mobile */

  .mobile-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    height: 80vh;
  }

  .mobile-column {
    flex: 1;
    margin-bottom: 10px;
    border: 1px solid violet;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0.9;
    border-radius: 10px;
  }

  .minertext {
    position: absolute;
    color: white;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: medium;
    font-family: 'NexaHeavy';
    height: 3.5vh;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    background-color: purple;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    opacity: 0.8;
    padding: 1vh;
    border: 1px solid black;
  }
  
  .nftMineMB {
      width: 11vh;
      height: 11vh;
      border-radius: 5px;
      margin: 0.5vh;
      padding: 0.5vw;
      cursor: pointer;
      opacity: 0.8;
  }

  .minethumbnailfalse{
    background-color: black;
  }

  .minethumbnailtrue{
    background-color: skyblue;
  }