.stake-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-left: 20%;
  height: calc(100vh - 90px);
}

@media (max-width: 768px) {
  .stake-container  {
    width: 92%;
  }
}

.stake-column {
  flex: 1;
  margin: 0.5vh 0.5vw 0vh 1vw;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  position: relative;
  height: auto;
  background-size: cover;
  background-position: center;
  opacity: 0.9;
  border: 0.25vh solid black;
}

.column-1 {
  background-image: url(../assets/images/medusa.jpg);
}

.column-2 {
  background-image: url(../assets/images/hawkman.jpg);
}

.column-3 {
  background-image: url(../assets/images/swampprotector.jpg);
}

.column-4 {
  background-image: url(../assets/images/medusamobile.jpg);
}

.column-5 {
  background-image: url(../assets/images/hawkmanmobile.jpg);
}

.column-6 {
  background-image: url(../assets/images/swampprotectormobile.jpg);
}

.farmbuttons {
  width: 80%;
  margin-bottom: 4px;
}
.farmbuttons:hover {  
  width: 100%;
  /* background-color: black; */
  opacity: 0.9;
}

.switchfarm {
  position: absolute;
  top: 17vh; 
  width: 7%;
  justify-content: center;
  cursor: pointer;
}

.stake-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.bar {
  width: 100%;
  font-family: 'NexaHeavy';
  color: pink;
  background-color: maroon;
  border-radius: 4px;
  text-align: center;
  opacity: 0.7;
  padding: 0.5vw 0vh 0.5vw 0vh;
}

.barMb {
  width: auto;
  padding: 0.1vh;
  margin-bottom: 1.7vh;
  font-size: 2.7vh;
  font-family: 'NexaHeavy';
  color: pink;
  background-color: maroon;
  border-radius: 4px;
  text-align: center;
  opacity: 0.7;
}

.inputbar {
  font-family: 'NexaHeavy';
  color: black;
  background-color: white;
  border-radius: 4px;
  text-align: center;
}

.greenbar {
  width: 100%;
  font-family: 'NexaHeavy';
  color: whitesmoke;
  background-color: limegreen;
  border-radius: 4px;
  text-align: center;
}

.greenbarMb {
  width: auto;
  padding: 0.1vh;
  font-size: 2.7vh;
  font-family: 'NexaHeavy';
  color: whitesmoke;
  background-color: limegreen;
  border-radius: 4px;
  text-align: center;
  margin: 1vh 0vw 1vh 0vw;
}

.orangeredbar {
  width: 100%;
  padding: 8px;
  margin-top: 1vh;
  font-family: 'NexaHeavy';
  color: pink;
  background-color: orangered;
  border-radius: 4px;
  text-align: center;
  opacity: 0.8;
  margin: 0.6vh 0vw 0.6vh 0vw;
}

.orangeredbarMb {
  width: auto;
  padding: 0.1vh;
  margin-top: 1vh;
  font-size: 2.7vh;
  font-family: 'NexaHeavy';
  color: pink;
  background-color: orangered;
  border-radius: 4px;
  text-align: center;
  opacity: 0.8;
  margin: 1vh 0vw 1vh 0vw;
}

.stake-form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid green;
  border-radius: 4px;
  text-align: center;
}

.stake-form button {
  width: 100%;
  padding: 8px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.stake-button {
  width: 100%;
  padding: 8px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 80%;
}

.stake-form button:hover {
  background-color: #45a049;
}

.inner-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.nanotxt{
  font-family: "NexaHeavy";
  font-size: 12px;
  color: #5B5B5B;
  text-align: left;
  padding: 5px 10px 5px 10px;
}

.smalltxt {
color: #5B5B5B;
font-family: "NexaHeavy";
font-size: 14px;
text-align: left;
padding: 5px 10px 5px 10px;
}

.stakesmalltxt {
  color: #5B5B5B;
  font-family: "NexaHeavy";
  font-size: 14px;
  text-align: left;
  padding: 5px 0px 5px 4px;
  }

.largetxt {
  color: white;
  font-family: 'NexaHeavy';
  font-size: 18px;
  text-align: left;
  padding: 5px 10px 5px 10px;
}

.display-container-0 {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: black;
  padding: 5px; 
  margin-top: 11vh;
  margin-bottom: 1px;
  width: 19vw;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.50);
  opacity: 0.7;
  left: 8px;
  z-index: -100;
}

.display-container {
  display: flex;
  border-radius: 8px;
  background-color: black;
  padding: 1px; 
  margin: 11vh 0.5vw 0vh 1vw;
  width: 100%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.50);
  opacity: 0.7;
  border: 1px solid whitesmoke;
}

@media (max-width: 768px) {
  .display-container {
    width: 100%;
  }
  .largetxt {
    font-size: 12px;
  }
  .status-container {
    width: 100%;
  }
}

.btn-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px 0px 10px 0px;
}

@media (max-width: 768px) {
  .btn-group {
    margin: 5px 0px 10px 0px;
  }
}

.btn-left-group {
  display: flex;
  background-color: #303030; /* Green background */
  cursor: pointer; /* Pointer/hand icon */
  padding: 12px 25px 12px 25px;
  font-size: 14px;
  transition: all .2s;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  border-right: 0;
  box-shadow: -16px 0 16px -8px #303030, 0 8px 16px -8px #303030, 0 -8px 16px -8px #303030;
  font-family: "NexaHeavy";
  color: white;
}

@media (max-width: 768px) {
  .btn-left-group {
    padding: 12px 10px 12px 15px;
  }
}

.btn-mid-group {
  display: flex;
  background-color: #303030; /* Green background */
  cursor: pointer; /* Pointer/hand icon */
  padding: 12px 25px 12px 25px;
  font-size: 14px;
  transition: all .2s;
  box-shadow: 0 8px 16px -8px #303030, 0 -8px 16px -8px #303030;
  font-family: "NexaHeavy";
  color: white;
}

@media (max-width: 768px) {
  .btn-mid-group {
    padding: 12px 10px 12px 10px;
  }
}

.btn-right-group {
  display: flex;
  background-color: #303030; 
  cursor: pointer; 
  padding: 12px 25px 12px 25px;
  font-size: 14px;
  transition: all .2s;
  box-shadow: 0 8px 16px -8px #303030, 16px 0 16px -8px #303030, 0 -8px 16px -8px #303030;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border-left: 0;
  font-family: "NexaHeavy";
  color: white;
}

@media (max-width: 768px) {
  .btn-right-group {
    padding: 12px 15px 12px 10px;
  }
}

.btn-left-group:hover {
  background-color: #00ff00;
  color: black;  
}

.btn-mid-group:hover {
  background-color: #00ff00;
  color: black;  
}

.btn-right-group:hover {
  background-color: #00ff00;
  color: black;  
}

.side-box {
  background-color: green;
  border: 1px solid white;
  border-radius: 25px;
  height: 20vh;
  width: 100%;
  }

.nanosmall{
  font-size: 12px;
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.info-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 20vw;
  width: 80vw;
}

.mobile-contain-box {
  display: flex;
  flex-direction: row;
  background-color: black;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.50);
  opacity: 0.7;
  padding-left: 4px;
  padding-right: 4px;
}

.display-container-user {
  display: flex;
  flex-direction: column;
  background-color: #303030;
  border-radius: 8px;
  margin: 30px 15px 20px 15px;
  padding: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.50);
}

.mobile-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
 
.status-container {
  display: flex;
  flex-direction: row;
  width: 100%;  
  margin: auto;
  margin: 20px 0px 20px 0px;
  justify-content: center;
}

.farm-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  color:#000000;
  width: 100%;
  height: 35px;
  padding: 10px 0px 10px 0px;
  margin: 10px 0px 5px 0px;
  border: 0;
  border-radius: 25px;
  font-family: "NexaHeavy";
  font-size: 14px;
  outline: none;
}

.approve-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  color:#000000;
  width: 100%;
  height: 35px;
  padding: 10px 0px 10px 0px;
  margin: 10px 0px 5px 0px;
  border: 0;
  border-radius: 25px;
  font-family: "NexaHeavy";
  font-size: 14px;
  outline: none;
}

.ncr{
  cursor: initial;
}


  /* Mobile */

  .mobile-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    height: 100vh;
  }

  .mobile-column {
    margin-bottom: 10px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    height: auto;
    background-size: cover;
    background-position: center;
    opacity: 0.9;
    border-radius: 10px;
    border: 1px solid white;
  }
  
