body {
  background-color: #000000;
}

/* Background Styles */

.centerpiece {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-size: contain;
  background-position: center;
  /* background-image: url(./assets/images/bg/centerpiece.png);   */
  z-index: 1;
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.klanButton {
width: 30%;
height: 9vh;
border-radius: 5px;
font-family: 'EvilEmpire';
font-size: larger;
align-items: center;
justify-content: space-around;
padding: 2vh 2vw 2vh 2vw;
margin: 0.5vh 0.5vw 0.5vh 0.5vw;
opacity: 0.8;
border: 0.5vh solid whitesmoke;
cursor: pointer;
}

.backgroundMobile {
  width: 100vw;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
}

.mainBackground0{
  background-image: url(./assets/images/bg/tournament.png);  
}

.mainBackground1{
  background-image: url(./assets/images/bg/apexdogs.jpg);  
}

.mainBackground2{
  background-image: url(./assets/images/bg/cerberus.gif);  
}

.mainBackground3{
  background-image: url(./assets/images/bg/mint.jpg);  
}

.mainBackground4{
  background-image: url(./assets/images/bg/launch.jpg);  
}

.mainBackground5{
  background-image: url(./assets/images/bg/assault.jpg);  
}

.mainBackground6{
  background-image: url(./assets/images/bg/zombie_sensei.jpg);  
}

.mainBackground7{
  background-image: url(./assets/images/silver-coins.jpg);  
}

.mainBackground8{
  background-image: url(./assets/images/bg/weapon_wolf.jpg);  
}

.mainBackground9{
  background-image: url(./assets/images/bg/regen_wolf.jpg);  
}

.mainBackground10{
  background-image: url(./assets/images/bg/claimassault.jpg);  
}

.mainBackground11{
  background-image: url(./assets/images/bg/claimdebilitate.jpg);  
}

.mainBackground12{
  background-image: url(./assets/images/bg/victory.jpg);  
}

.mainBackground13{
  background-image: url(./assets/images/customize.gif);  
}

.mainBackground14{
  background-image: url(./assets/images/bg/farmland.jpg);  
}

.mainBackground15{
  background-image: url(./assets/images/cerberus.jpg);  
}

.mainBackground16{
  background-image: url(./assets/images/bg/gears.jpg);  
}

.mainBackground17{
  background-image: url(./assets/images/bg/tracker.jpg);  
}

.mainBackground18{
  background-image: url(./assets/images/bg/scabbard.jpg);  
}

.mainBackground19{
  background-image: url(./assets/images/bg/custom_wolf.jpg);  
}

.mainBackground20{
  background-image: url(./assets/images/bg/leaderboard.jpg);  
}

.mainBackground21{
  background-image: url(./assets/images/bg/houseboard.jpg);  
}

 /* Start Menu Background Styles */
.smbkg0 {  
  background-image: url(./assets/images/bg/arsenal.png);
}

.smbkg1 {  
  background-image: url(./assets/images/bg/launch_black.jpg);
}

.smbkg2 {  
  background-image: url(./assets/images/bg/arena_black.jpg);
}

.smbkg3 {  
  background-image: url(./assets/images/bg/mint_black.jpg);
}

.smbkg4 {  
  background-image: url(./assets/images/bg/winnings.png);
}

.smbkg5 {  
  background-image: url(./assets/images/bg/tools.png);
}

.smbkg6 {  
  background-image: url(./assets/images/bg/victory_black.jpg);
}

.smbkg7 {  
  background-image: url(./assets/images/bg/start.jpg);
}

.smbkg8 {  
  background-image: url(./assets/images/bg/zombie_sensei_black.jpg);
}

.smbkg9 {  
  background-image: url(./assets/images/bg/gold-coins.jpg);
}

.smbkg10 {
  background-image: url(./assets/images/bg/weapon_wolf_black.jpg);
}

.smbkg11 {
  background-image: url(./assets/images/bg/regen_wolf_black.jpg);
}

.smbkg12 {
  background-image: url(./assets/images/bg/claimassault_black.jpg);
}

.smbkg13 {
  background-image: url(./assets/images/bg/claimdebilitate_black.jpg);
}

.smbkg14 {
  background-image: url(./assets/images/bg/art_of_silence.jpg);  
}

.smbkg15 {
  background-image: url(./assets/images/bg/farmland_black.jpg);  
}

.smbkg16 {
  background-image: url(./assets/images/bg/tools_black.jpg);  
}

.smbkg17 {
  background-image: url(./assets/images/bg/purpledogs_black.png);  
}

.smbkg18 {
  background-image: url(./assets/images/bg/tracker_black.jpg);  
}

.smbkg19 {
  background-image: url(./assets/images/bg/assault_black.jpg);  
}

.smbkg20 {
  background-image: url(./assets/images/bg/custom_wolf_black.jpg);  
}

.smbkg21 {
  background-image: url(./assets/images/bg/klans_black.jpg);  
}
/* Top Desktop Styles  */

.topLogin {
  position: fixed;
  height: 20vh;
  right: 0px;
  opacity: 0.8;
  padding: 0.5vh 0.5vh 0.5vh 0.5vh;
}

.topLogo {
  position: fixed;
  left: 0px;
  opacity: 0.8
}

.connectButton {
  background-color: #f8e5c4
}

/* Top Mobile  Styles  */

.topLogoMobile {
  position: fixed;
  opacity: 0.8;
  left: 0px;
  margin: 2px;
}

.topLoginMobile {
  height: 10vh;
  padding: 0.5vh 0.5vh 0.5vh 0.5vh;
  opacity: 0.8;
}

.centerAlign {  
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
}

.translation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.translation1 {
  position: fixed;
  display: flex;
  top: 17vh;
  left: 21vw;
}

.translation2 {  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
}


.central { 
  display: flex;
  flex-direction: column;
  align-items: "center";
  border: 2px solid white;
  height: 65vh;
  margin-left: 19vw;
}

.centered { 
  position: fixed;
  display: flex;
  height: auto;
  justify-content: space-between;
  align-items: "center";
}

.icons1 {
  width: 4.5vw;
  cursor: pointer;
  margin: 0.25vh 0vw 0.25vh 0vw;
}

.div1{
  top: 17vh;
  margin-left: 12vw;
  width: 37vw;
  border: 2px solid yellowgreen;
}

.div2{
  top: 27vh;
  margin-left: 10vw;
  width: 40vw;
  border: 2px solid royalblue;
}

.div3{
  top: 37vh;
  margin-left: 7.5vw;
  width: 44.5vw;
  border: 2px solid goldenrod;
}

.div4{
  top: 47.5vh;
  margin-left: 10vw;
  width: 40vw;
  border: 2px solid brown;
}

.div5{
  top: 58vh;
  margin-left: 12vw;
  width: 37vw;
  border: 2px solid aqua;
}

.mkLarge{
  font-size: 17vh;
  font-family: 'MkMyth';
}

.mkBig {
  font-size: 13vh;
  font-family: 'MkMyth';
}

.mkMedium{
  font-size: 10vh;
  font-family: 'MkMyth';
}

.mkMid{
  font-size: 7vh;
  font-family: 'MkMyth';
}

.mkSmall{
  font-size: 5vh;
  font-family: 'MkMyth'; 
}

.mkMicro{
  font-size: 3.5vh;
  font-family: 'MkMyth';
}

.mkNano{
  font-size: 2vh;
  font-family: 'MkMyth';
}

.mkMolecule{
  font-size: 1vh;
  font-family: 'MkMyth';
  padding: 0.25vh;
}

.mkAtom{
  font-size: 0.5vh;
  font-family: 'MkMyth';
}

.mkAuto{
  font-size: auto;
  font-family: 'MkMyth';
}

.opacity8 {
  opacity: 0.8;
}

.arrowSelectorRight {
 background-color: white;
 border-radius: 100%;
 border: 1vh solid black;
 opacity: 0.5;
 cursor: pointer;
}

.arrowSelectorRight:hover {
  background-color: gold;
 }

.arrowSelectorLeft {
  background-color: white;
  border-radius: 100%;
  border: 1vh solid black;
  opacity: 0.5;
  cursor: pointer;
}

.arrowSelectorLeft:hover {
  background-color: gold;
 }

 .connectedGif {
  opacity: 0.8;
  height: 10vh;
  width: 80vw;
 }

/* Font Face Types */
@font-face {
  font-family: 'NexaHeavy';
  src: url(./fonts/Nexa-Heavy.ttf) format('truetype');
}

@font-face {  
  font-family: 'RealVirtue';
  src: url(./fonts/REALVIRTUE.ttf) format('truetype');
}

@font-face {  
  font-family: 'EvilEmpire';
  src: url(./fonts/EvilEmpire.ttf) format('truetype');
}

@font-face {  
  font-family: 'MkMyth';
  src: url(./fonts/mkmyth.ttf) format('truetype');
}

@font-face {  
  font-family: 'Harlow';
  src: url(./fonts/harlowsi.ttf) format('truetype');
}

/* Sound styles */
.swdesktop {
  position: fixed;
  display: flex;
  top: 35vh; 
  width: 7.5vw;
  flex-direction: column;
  height: 28vh;
}

.swdesktop2 {
  position: fixed;
  display: flex;
  top: 47vh; 
  width: 6vw;
  flex-direction: column;
  height: 28vh;
}

.swmobile {
  display: flex;
  flex-direction: row;
  width: auto;
  height: 8vh;
  margin-left: 1vw;
  align-content: center;
  justify-content: space-evenly;
  background-color: black;
}

.soundicon {
  border-radius: 250px;
  margin-left: 0.5vw;
  margin-top: 1.5vh;
  cursor: pointer;
  opacity: 0.5;
}

.soundicon:hover {
  opacity: 0.95;
}

.sheight{
  height: 3vw;
  width: 3vw;
}

.sheight:hover {
  margin-left: 4vh;
}

.sheightmb{
  height: 5vw;
  width: 5vw;
}

.sheightmb:hover {
  margin-left: 2vh;
}

.theight{
  height: 5.5vw;
  width: 5.5vw;
}

.theightmb{
  height: 6.5vw;
}

.soundwidth{
  position: fixed;
  display: none;
  max-width: 10vw;
  max-height: 10vh;
  /* border: 1px white solid; */
}

.tutorial {
  border: double 0.25vw;
  padding: 0.25vh;
  border-radius: 1vh;
  font-family: 'mkMyth';
  font-size: 2vh;
}

.alignCenter {
  position: fixed ;
  display: flex;
  flex-direction: column;
  margin: 0.5vh 0vh 0.5vh 0vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Start Menu Styles */

.startMenu {
  display: flex;
  flex-wrap: wrap;
  /* height: 80vh; */
  width: 80vw;
  margin: 2.5vh 0vh 0vh 0vh;
  padding: 2vh 0vh 2vh 0vh;
  opacity: 0.8;
  border: 1vh solid rgba(255, 255, 255, 0.5);  
  border-radius: 4vh;
  align-items: center;
  justify-content: center;   
  background-size: cover;
  background-position: center;
  box-shadow: 0px 5px 30px rgba(16, 20, 22, 0.5);
}

.startMenuMobile {
  display: flex;
  flex-wrap: wrap;
  /* height: 80vh; */
  width: 80vw;
  padding: 2vh;
  opacity: 0.8;
  border: 0.5vh solid rgba(240, 240, 192, 0.5);    
  border-radius: 5vh;
  align-items: center;
  justify-content: center;  
  background-size: cover;
  background-position: center;
  box-shadow: 0px 5px 30px rgba(16, 20, 22, 0.5);
}

.veil {
  background-color: black;
  height: 100%;
  width: 100%;
  z-index: -100;
}

.expressMenu {
  display: flex;
  flex-wrap: wrap;
  height: 9vh;
  width: 80vw;
  opacity: 0.8;
  border-radius: 2vh;
  align-items: center;
  justify-content: center;   
  background-color: black;
  box-shadow: 5px 5px 15px rgba(102, 101, 101, 0.5);
  margin: 0.75vh 0vw 0vh 0vw;
}

.expressMenuMobile {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  opacity: 0.8;
  border: 0.2vh solid whitesmoke;
  align-items: center;
  justify-content: center;  
  background-color: black;
}

.xmbdiv {
  flex-direction: column;
}

.backgroundTiles {  
  background-size: 210px 114px;
  background-repeat: repeat;
}

.startMenuBox {
  height: 23vh;
  width: 23vh;  
  border: 0.7vh solid rgba(241, 241, 238, 0.85);  
  background-size: cover;
  background-position: center;
  margin: 3vh 3vh 3vh 3vh;
  border-radius: 4vh;
  cursor: pointer;
  color: white;
  font-family: 'mkMyth';
  font-size: 2.5vh;
}
.startMenuBox:hover {
  height: 30vh;
  border: 1vh solid gold;
  margin: 1vh 1vh 1vh 1vh;
  font-size: 5vh;
}

.expressMenuBox {
  height: 6vh;
  width: 6vh;
  border: 0.25vh solid white;
  background-size: cover;
  background-position: center;
  margin: 1vh 1vh 1vh 1vh;
  border-radius: 1vh;
  cursor: pointer;
}

.tspace{
  margin: 0.2vh 0vw 0.2vh 0vw;
}

.expressMenuBox:hover {
  height: 8vh;
  border: 0.5vh solid gold;
}

.startMenuBoxMobile {
  height: 12vh;
  width: 12vh;
  border: 0.5vh solid rgba(240, 240, 192, 0.85);    
  background-size: cover;
  background-position: center;
  margin: 0.5vh 2vh 0.5vh 2vh;
  border-radius: 2vh;
  cursor: pointer;
}
.startMenuBoxMobile:hover {
  height: 18vh;
  border: 1vh solid yellow;
}

.expressMenuBoxMobile {
  height: 5vh;
  width: 10vh;
  border: 0.2vh solid white;  
  background-size: cover;
  background-position: center;
  margin: 1vh 2vh 1vh 2vh;
  border-radius: 1vh;
  cursor: pointer;
  text-shadow: 2px 2px 8px black;
}


.box1bkg {  
  background-image: url(./assets/images/bg/raging_wolf.jpg);
}

.box2bkg {  
  background-image: url(./assets/images/bg/avatar.jpg);
}

.box3bkg {  
  background-image: url(./assets/images/bg/arena.jpg);
}

.box4bkg {  
  background-image: url(./assets/images/bg/superdog1.jpg);
}

.box5bkg {
  background-image: url(./assets/images/bg/gold-coins.jpg);
}

.box6bkg {
  background-image: url(./assets/images/bg/gears.jpg);
}

.box7bkg {
  background-image: url(./assets/images/bg/trophy.jpg);
}

.box8bkg {
  background-image: url(./assets/images/bg/goldwolf.jpg);
}

.box9bkg {
  background-image: url(./assets/images/bg/klans.jpg);
}

.onchainData {
  position: fixed;
  top: 10vh;
  right: 0px;
  margin: 1vh 0.5vh 0vh 0vh;
  width: 14.4vw;
  padding: 1vh 0vh 0vh 0vh;
  height: calc(100vh - 10vh);
  opacity: 0.85;
  border-radius: 2vh;
 }

.arsenalDiv {
 display: flex;
 flex-direction: column;
 margin: 1vh 0.5vh 0vh 0vh;
 width: calc(100vw - 19.8% - 0.5vw);
 opacity: 0.85;
 /* border: 1px solid white; */
}
 
.divBlack {
  position: fixed;
  top: 10vh;
  left: 20.9vw;
  width: 64.5vw;
  height: 90vh;
  background-color: black;
  opacity: 0.85;
  margin: 1vh 0vh 0vh 0vh;
  border-radius: 2vh 0vh 0vh 0vh;
 }

.arsenalDivMobile {
  display: flex;
  flex-direction: column;
  width: 100vw;
  /* border: 1px solid yellowgreen; */
  opacity: 0.9;
  margin: 0vh 0vh 1vh 0vh;
 }

.welcomeImage {
  height: 48vh;
  margin-top: 10px;
}


/* style for the nftloader division */
.nftloader {
  position: fixed; /* this positions the division beneath the header */
  display: flex; 
  flex-direction: row;
  flex-wrap: wrap;
  background-color: black;
  left: 21vw; /* aligns the division to the left of the page */
  width: 26vw; 
  min-height: 10vh;
  max-height: 100vh;
  padding: 1vh; 
  opacity: 0.9;
  border: 1px solid ; 
  overflow: auto;
  padding: 1vh 0vh 1vh 2vh;
   /* z-index: -1; */
}

.detailLoader {
  position: absolute; /* this positions the division beneath the header */
  right: 14.5vw; /* aligns the division to the right of the page */
  width: 37.2vw; /* spans a third of the width of the page */
  height: 100vh;
  background-color: black;
  border: 1vh black solid;
  margin-right: 1px;
  margin-left: 1px;
  opacity: 0.96;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 4vh;
}

.topfeature{  
  color: whitesmoke;
  font-size: 5vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.bottomfeature{  
  font-size: 7vh;
  padding-top: 1vh;
}

.largeNFT {
  /* padding-top: 0.5vh; */
  width: 100%;
  border-radius: 0vh 4vh 4vh 4vh;
}

.leftOff0 {
  opacity: 0.1;
  cursor: no-drop;
}

.rightOff22 {
  opacity: 0.1;
  cursor: no-drop;
}

.iconic {
  border-top-style: double;
  border-left-style: double;
  border-bottom-style: double;
  width: 100%;
  cursor: pointer;
  
  writing-mode: vertical-lr;
  text-orientation: upright;
}
.iconic:hover {
  width: 11.5vw;
}

.icons {
  width: 7.5vw;
  cursor: pointer;
  margin: 1vh 0vh 1vh 0vh;
}
.icons:hover {
  width: 11.5vw;
}

.nftText {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  font-family: 'NexaHeavy';
  font-weight: 500;
}

.nftImage {
  width: 5.5vw;
  height: 5.5vw;
  border-radius: 5px;
  margin: 0.25vw 0vw 0vw 0.75vw;
  cursor: pointer;
}

.texttheme {
  text-decoration: none;
  text-align: center;
  font-size: 1.5vh;
  font-family: 'NexaHeavy';
  font-weight: normal;
}

.tpgtext {
  background-image: url(./assets/images/pawprints0.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.divgtext {
  font-size: 3.5vh;
  font-weight: bold;
  background-image: url(./assets/images/pawprints0.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.bp0gtext {
  background-image: url(./assets/images/pawprints1.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.bp1gtext {
  background-image: url(./assets/images/pawprints2.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.bp2gtext {
  background-image: url(./assets/images/pawprints3.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.bp3gtext {
  background-image: url(./assets/images/pawprints4.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.bp4gtext {
  background-image: url(./assets/images/pawprints5.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.bp5gtext {
  background-image: url(./assets/images/pawprints6.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.bp6gtext {
  background-image: url(./assets/images/pawprints7.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.bp7gtext {
  background-image: url(./assets/images/pawprints8.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.bp8gtext {
  background-image: url(./assets/images/pawprints9.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.bp9gtext {
  background-image: url(./assets/images/pawprints2.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.bp10gtext {
  background-image: url(./assets/images/pawprints9.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.bp11gtext {
  background-image: url(./assets/images/pawprints1.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.bp12gtext {
  background-image: url(./assets/images/pawprints5.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.bp13gtext {
  background-image: url(./assets/images/pawprints6.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.bp14gtext {
  background-image: url(./assets/images/pawprints7.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.bp15gtext {
  background-image: url(./assets/images/pawprints1.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.bp16gtext {
  background-image: url(./assets/images/pawprints6.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

.mobilenavigate {
  display: flex;
  flex-direction: row;
  padding: 4px;
  justify-content: space-between;
  width: 100vw;
  background-color: brown;
  opacity: 0.8;
}

.arsenalcontent {
  flex-direction: column;
  height: auto;
}

.nftfloater {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 20%;
  width: 100vw;
  padding: 0.5vw;
  overflow: auto;
 }

.nftcarriage {
  height: auto;
  width: 100vw;
}
  
.mobilelayer{
  width: 100vw;
  background-color: black;
}
  
.featurespanel {  
  height: 30vh;
  font-family: 'EvilEmpire';
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
  background-image: url(./assets/images/zarpazo-claw-hd.gif);
}

.featurespanelMB {  
  height: 20vh;
  font-family: 'EvilEmpire';
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
  background-image: url(./assets/images/zarpazo-claw-hd.gif);
}

.mblb{
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
  width: 30%;
  margin: 3px;
  border: 0.5vw solid gray;
  border-radius: 2vh;
  }

.detaildivmobile {
  display: flex; 
  flex-direction: row;
  flex-wrap: wrap;
  opacity: 0.85;
  /* font-family: "REALVIRTUE"; */
  align-items: center;
  justify-content: center;
}

.point{
  cursor: pointer;
}


.notificationsData {
  /* position: fixed; */
  position: absolute;
  padding: 0vh 0vh 0vh 0vh;
  right: 0.05vw;
  margin: 0vh 0.05vh 0vh 0vh;
  width: 22vw;
  top: 11vh;
  height: calc(100vh - 25vh);
  border-radius: 0vh 2vh 0vh 0vh;
  /* background-color: black; */
  /* border: 1px dotted red; */
  /* z-index: -1; */

  
  min-height: 10vh;
  max-height: 75vh;
  overflow: auto;
 }

.notificationsbar{
  position: absolute;
  top: 11vh;
  width: 23%;
  right: 10px;
  min-height: 40px;
  max-height: 70vh;
}

 .helpDiv{
  display: flex;
  border: 1.5vh solid rgb(73, 72, 72);
  border-radius: 5vh;
  width: auto;
  background-color: black;
  opacity: 0.85;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0px 5px 30px rgba(16, 20, 22, 0.5);
 }

   .helpDivMobile{
  display: flex;
  flex-direction: column;
  border-radius: 2vh;
  width: 75vw;
  opacity: 0.85;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  border: 0.5vh solid rgb(73, 72, 72);
  box-shadow: 0px 5px 30px rgba(16, 20, 22, 0.5);
  cursor: pointer;
 }

 .helpCol {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 1vh 1vh 1vh 1vh;
  padding: 1vh 1vh 1vh 1vh;
 }
 
.helpMenuBox {
  height: 25vh;
  width: 25vh;
  border: 1vh solid black;
  background-size: cover;
  background-position: center;
  margin: 4vh 4vh 4vh 4vh;
  border-radius: 4vh;
  cursor: pointer;
}
 
.helpMenuBoxMobile {
  height: 12vh;
  width: 12vh;
  border: 0.5vh solid black;
  background-size: cover;
  background-position: center;
  border-radius: 2vh;
  cursor: pointer;
  margin: 0.5vh 0vh 0.5vh 0;
}

.vis-1 {  
  background-image: url(./assets/images/approve-vis-1.png);  
}

.attack-1 {  
  background-image: url(./assets/images/approve-vis-1.png);  
}

.buttongold {
  cursor: pointer;
  border: 2px outset white;  
  border-radius: 10px;
  padding: 4px;
  width: auto;
  margin: 2px; 
  box-shadow: 7px 6px 28px 1px rgba(170, 171, 172, 0.8); 
  text-decoration: none; 
  font-size: 16px;
  outline: none; 
  transition: 0.2s all; 
}

.buttongold:active{  
  transform: scale(0.98); 
  box-shadow: 3px 2px 22px 1px rgba(170, 171, 172, 0.8); 
}

.layoutDiv {
  font-family: 'mkMyth';
  position: fixed;
  left: 21vw;
  width: 64.5vw;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layoutDivMobile {
  display: flex;
  margin: 1vh 0.25vw 0.25vh 0.25vw;
  width: 99vw;
  height: 5vh;
  align-items: center;
  justify-content: center;
}

.layoutBox {
  background-color: black;
  opacity: 0.5;
  border-radius: 2vh;
  padding: 1vh 1vh 1vh 1vh;
  border: 0.2vh solid white;
}

.layoutBoxMobile {
  background-color: black;
  opacity: 0.5;
  border-radius: 2vh;
  padding: 1vh 1vh 1vh 1vh;
  border: 0.2vh solid white;
}

.slimBorder {
  border: 0.2vh solid white;
  box-shadow: 0px 5px 30px rgba(16, 20, 22, 0.5);
}

.portrait {
  height: 45vh;
  width: 45vh;
  border-radius: 5vh;
  box-shadow: 0px 5px 30px rgba(16, 20, 22, 0.5);
}

.portraitMobile {
  height: 35vh;
  width: 35vh;
  border-radius: 27vh;
  box-shadow: 0px 5px 30px rgba(16, 20, 22, 0.5);
  border: 0.1vh outset black;
  background-position: center;
}

.portraitHTH {
  width: 45vh;
  height: 45vh;
  border: 11px solid rgba(240, 240, 192, 0.347);
  border-radius: 50vh; 
  margin: 0vh 0vw 1.5vh 0vw;
}

.portraitHTHMobile {
  width: 35vh;
  height: 35vh;
  border: 11px solid rgba(240, 240, 192, 0.347);
  border-radius: 50vh; 
  margin: 1vh 0vw 2vh 0vw;
}

.miniText {
  font-family: NexaHeavy;
  font-size: small;
  opacity: 0.9;
}

.miniTextMB {
  font-family: NexaHeavy;
  font-size: small;
  opacity: 0.9;
}

.minileaderboard {
  display: flex;
  flex-direction: column;
  border: 2px darkslategray solid;
  height: auto;
  width: 6.6vw;
  background-color: black;
  opacity: 0.8;
  border-radius: 2vh;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}

.minileaderboardMB {
  display: flex;
  border: 1px darkslategray solid;
  width: 100vw;
  background-color: black;
  align-content: center;
  cursor: pointer;
  justify-content: space-evenly;
}

.fullleaderboard {
  position: fixed;
  left: 20.9vw;
  top: 9vh;
  width: 79vw;
  height: 91vh;
  opacity: 0.85;
  background-color: black;
  z-index: -1;
  overflow-y: auto;
  overflow-x: hidden;
}

.leaderboardlayer {  
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  font-family: 'NexaHeavy';
  font-weight: bold;
  font-size: medium;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0.5vh 0vw 0.5vh 0vw;
  border: 1vh solid black;
  border-top-left-radius: 2vw;
  background-color: black;
  opacity: 0.84;
  /* border-bottom-left-radius: 2vw; */
}

.noticeDivMB {
  z-index: -1;
}

.housedisplay {
  display: flex;
  padding: 1vh;
  background-color: black;
  align-items: center;
  justify-content: space-between;
  opacity: 0.9;
  cursor: pointer;
}

@keyframes colorWave {
  0% {
    background-position: -90vw;
  }
  40% {
    background-position: 90vw;
  }
  100% {
    background-position: 90vw;
  }
}

.hub-title {
  animation: colorWave 30s linear infinite;
}

/*********************************** Neanderthal styling ********************************** */


/* Set the style for the scrollbar */
::-webkit-scrollbar {
  width: 1vw;
}

/* Set the style for the thumb of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 10px;
  border: 2px gray solid;
  cursor: grab;
  opacity: 0.8;
}

/* style for the nftloader division */
.detaildiv {
  position: fixed; /* this positions the division beneath the header */
  display: flex; 
  flex-direction: column;
  height: calc(100vh - 43px);
  top: 43px; /* positions the division 50px beneath the header */
  right: 1px; /* aligns the division to the left of the page */
  width: 8.5%;
  background-color: black;
  opacity: 0.85;
  font-family: "REALVIRTUE";
  padding-top: 0.6vh;
}

.fitiem {  
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
  height: 9.5vh;
  background-color: black;
  border: 1px solid gray;
  border-radius: 2vh;
}

.activitytracker {
  position: absolute; /* this positions the division beneath the header */
  top: 20vh; /* positions the division 50px beneath the header */
  left: 24vw; /* aligns the division to the right of the page */
  width: 50%; 
  height: 88vh; 
  overflow: auto;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
}

.actheader {
  position: fixed;
  top:  9vh;
  left: 35vw;
  background-image: linear-gradient(#E21143, #FFB03A);
  background-clip: text;
  color: transparent;
  font-size: xx-large;
  font-family: Impact;
  letter-spacing: 2px;
  cursor: pointer;
}

.textheader {
  background-image: linear-gradient(#E21143, #FFB03A);
  background-clip: text;
  color: transparent;
  font-size: 6vh;
  letter-spacing: 2px;
  cursor: pointer;
  font-family: "Harlow";
  width: 99vw;
}

.scoreboardImg {
  padding: 1vh;
  border-radius: 1vh;
  opacity: 0.9;
  height: 7vh;
  margin: 0vh 0vh 0vh 0vh;
}

.scoreboardImgMobile {
  padding: 1vh;
  border-radius: 1vh;
  opacity: 0.9;
  height: 6vh;
  margin: 0vh 0vh 0vh 0vh;
}


.activityTrackerImgMobile {
  width: 80vw;
}

.button {
border-radius: 20px;
cursor: pointer;
background-color: whitesmoke;
border: 2px solid yellow; 
width: 70px;
margin: 2px;
margin: 0.2vh 0.4vh 0.2vh 0.4vh;
}

.button0 {
  cursor: pointer;
  background-color: whitesmoke;
  /* border: 2px solid yellow;   */
  border-radius: 10px;
  padding: 4px;
}

.button1 {
  cursor: pointer;
  background-color: gold;
  border-radius: 10px;
  padding: 4px;
  margin: 0.2vh 0.4vh 0.2vh 0.4vh;
}

.button2 {
  cursor: pointer;
  background-color: gray;
  border-radius: 10px;
  padding: 4px;
  width: 10vh;
}
.button2:hover{
  background-color: whitesmoke;
}

.slider {
  position: absolute;
  overflow: hidden;
  top: 8%;
  height: calc(100%-8%);
  left: 1%;
  width: 25%;
}

.slider {
  animation: slide 10s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

.menu1{
  position: fixed;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  top: 14vh;
  cursor: pointer;
  width: 5vw;
  height: 11vh;
}

.wolfslash{
  width: 5vw;
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
  background-image: url(./assets/images/wolfslash.png);
  margin-left: 1vh;
  margin-top: 7.5vh;
  text-align: left;
  font-size: 40px;
  font-family: 'EvilEmpire';
}

.allPlayersText{
  position: fixed;
  align-items: center;
  justify-content: space-between;
  top: 29vh;
  left: 0.5vh;
  cursor: pointer;
  /* border: 1px solid pink; */
  width: 6vw;
  height: 6vh;  
  margin: 0vh 0vw 0vh 0.25vw;
  font-family: 'EvilEmpire';
  font-size: 5vh;
  /* writing-mode: vertical-lr;
  text-orientation: upright; */
  text-shadow: 2px 2px 8px black;
}

.iconsbkg {
  width: 6.5vw;
  border-radius: 250px;
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
}
.iconsbkg:hover {
  width: 10.5vw;
  border-radius: 250px;
}

.goldpayout {  
  background-image: url(./assets/images/icons/wallet.gif);
  cursor: pointer;
}

.list1 {
  font-weight: bold;
  text-align: center;
  margin: 4px;
  padding: 4px;
  background-image: linear-gradient(#E21143, #FFB03A);
  background-clip: text;
  color: transparent;
}

.hunted{
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  top: 48px;
  width: 7%;
  min-height: calc(95% - 48px);
  max-height: calc(99% - 48px);
  Left: 20.5vw;
  /* color: yellow; */
  font-size: medium;
  font-family: 'NexaHeavy';
  overflow: auto;
}
 
.menu2{
  position: fixed;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 100vh;
  align-items: center;
  justify-content: center;
  width: 13vw;
  left: 7vw;
  border-left-style: solid;
  border-right-style: solid;
  border-color: black;
  border-width: 1vh;
  background-color: black;
  opacity: 0.85;
  background-size: cover;
  background-position: left;
}

.menu4{
  position: fixed;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  left: 18.2vw; 
  top: 65vh;
  width: 5vw;
  /* color: yellow; */
  font-size: medium;
  font-family: 'NexaHeavy'; 
  cursor: pointer;
}

.txt{
  font-size: 10px;
  text-decoration: none;
  text-align: center;
  font-family: 'NexaHeavy';
  font-weight: normal;
}

.notifyInput {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  top: 75vh;
  min-width: 22%;
  max-width: 30%;
  right: 1%;
  min-height: 128px;
  max-height: 129px;  
  z-index: 1; /* set z-index value to 1 to place it below middle image */
}

.namenotification{
  position: absolute;
  top: 1%;
  left: 30%;
  text-align: right;
  font-size: 40px;
  font-family: 'mkMyth'; 
  min-height: 4vh;
  max-height: 100%;
  width: auto;
  opacity: 0.9;
}

.notifications{
  display: flex;
  flex-direction: column;
  top: 55px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.notify{
  display: flex;
  flex-direction: row;
  width: 99%;
  border-top: solid 0.5vh gray;
  border-bottom: solid 0.5vh gray;
  opacity: 0.85;
  align-items: center;
  justify-content: space-evenly;
  margin: 0.25vh 0vw 0.25vh 0vh;
  font-family: 'NexaHeavy';
  cursor: pointer;
}

.notifyMb{
  display: flex;
  flex-direction: row;
  opacity: 0.85;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid black;
  cursor: pointer;
}

.notifyText{
  font-size: medium;
  font-family: 'NexaHeavy';
  color: black;
}

.notifyImg{
  height: 40px;
  width: 40px;
  margin: 4px; 
  border-radius: 15px;
}

.trackView{
  border-radius: 25px;
  width: 50px;
  height: 50px;
  line-height: 50px;  
  /* border: 1px yellow solid; */
  margin: 3px;
  font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  /* color: yellow; */
}

.stakenotify {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 3px;
  background-color: black;
  opacity: 0.7;
  justify-content: center;
  margin: 1vh 0vw 0vh 0vw;
  padding: 6px;
}

.brGold {
  border: 3px solid gold;
}

.brBlue {
  border: 3px solid skyblue;
}

.brORed{
  border: 3px solid orangered;
}

.brRed{
  border: 3px solid red;
}

.brMaroon{
  border: 3px solid maroon;
}

.brMagenta {
  border: 3px solid magenta;
}

.brPink {
  border: 3px solid pink;
}

.brTeal {
  border: 3px solid teal; 
}

.brOrange {
  border: 3px solid orange; 
}

.brViolet {
  border: 3px solid violet; 
}

.image-wrapper {
  position: relative;
}

.image-wrapper:hover::before {
  content: attr(title);
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  opacity: 0.8;
  white-space: nowrap;
}

.errordisplay{
  text-align: center;
  align-items: center;
  border-radius: 25px;
  font-size: bolder;
  font-family: 'NexaHeavy';
  margin-bottom: 6px;
  text-shadow: 2px 2px 8px black;
  cursor: pointer;
}

.linkbutton {
  cursor: pointer;
  font-size: 15px;
  font-weight: bolder;
  font-family: 'NexaHeavy';
  background-color: transparent;
  border: none;
  padding: 4px;
  text-decoration: none;
  text-shadow: 2px 2px 8px black;
}

.scorebutton {
  cursor: pointer;
  color: white;
  font-size: 2.5vh;
  font-weight: bolder;
  font-family: 'NexaHeavy';
  background-color: transparent;
  border: none;
  padding: 4px;
  text-decoration: none;
  text-shadow: 2px 2px 8px black;
}
.scorebutton:hover {
  color: yellow;
  font-size: 3vh;
  text-shadow: 2px 2px 8px orange;
}

.nbmisc{
width: 90%;
padding: 4px;
opacity: 0.6;
font-size: 14px;
font-family: 'MkMyth';
letter-spacing: 1px;
cursor: pointer;
}

.articon {
  cursor: pointer;
  color: white;
  font-size: 40px;
  height: 10%;
  font-family: 'NexaHeavy';
  background-color: transparent;
  text-decoration: none;
  margin-top: 2px;
}

.iconbkg {
  border-radius: 25px;
  padding: 8px;
  opacity: 0.8;
  color: whitesmoke;
  border: 1px solid black;
}

.whitesmoke {  
  background-color: whitesmoke;
}

.black {  
  background-color: black;
}

.yellow {  
  background-color: yellow;
}

.olive {  
  background-color: darkolivegreen;
}

.darkred {  
  background-color: darkred;
}

.green {  
  background-color: green;
}

.red {  
  background-color: red;
}

.teal {  
  background-color: teal;
}

.darkgreen {  
  background-color: darkgreen;
}

.wood {  
  background-color: burlywood;
}

.gold {  
  background-color: gold;
}

.seagreen {  
  background-color: lightseagreen;
}

.magenta {  
  background-color: magenta;
}

.brown {  
  background-color: brown;
}

.springgreen {
  background-color: springgreen;
}

.slategray {
  background-color: darkslategrey;
}

.gradient-text {
  background-image: linear-gradient(#E21143, #FFB03A);
  background-clip: text;
  color: transparent;
  font-family: 'NexaHeavy';
  margin-bottom: 10px;
  text-align: center;
}

.gtext {
  background-image: linear-gradient(#E21143, #FFB03A);
  background-clip: text;
  color: transparent;
  font-family: 'NexaHeavy';
  text-align: center;
}

.namegtext{  
  background-image: url(./assets/images/pawprints2.png);
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: left;
}

.a-format {
  color: white;
  background-color: rgb(130, 4, 4); 
  /* font-weight: bold; */
  border: 2px solid white;  
  border-radius: 10px;
  margin: 2px;
  padding: 4px;
  text-align: center;
  align-items: center;
  opacity: 0.9;
  cursor: pointer;
}

.d-format {
  color: white;
  background-color: rgb(4, 82, 142); 
  /* font-weight: bold; */
  border: 2px solid white;
  border-radius: 10px;
  margin: 2px;
  padding: 4px;
  text-align: center;
  align-items: center;
  opacity: 0.9;
  cursor: pointer;
}

.c-format {
  color: gold;
  background-color: transparent; 
  font-weight: bold;
  font-family: 'NexaHeavy';
  border-radius: 10px;
  margin: 2px;
  padding: 4px;
  text-align: center;
  align-items: center;
  opacity: 0.7
}

.aBoard{
  color: red;
  margin-top: 6px;
  margin-bottom: 2px;
  width: 100%; /* spans a third of the width of the page */
  height: 285px; /* spans the remaining length of the page */
  background-image: linear-gradient(black, red, black);
  cursor: initial;
}

.dBoard{
  /* color: yellow; */
  margin-top: 6px;
  margin-bottom: 2px;
  width: 100%; /* spans a third of the width of the page */
  height: 285px; /* spans the remaining length of the page */
  /* background-image: linear-gradient(black, yellow, black); */
  cursor: initial;
}

.notifyIcon{
  height: 250px;
  margin-top: 4px;
  margin-bottom: 1px;
}

.trackedIcon{
  height: 30px;
}

.brblack{
  border: 3px solid black;
}

.brwhite{
  border: 3px solid white;
}

.vText{
  writing-mode: vertical-rl;
  text-orientation: upright;
}

.fs {
font-size: 15px;
font-weight: bold;
padding: 4px;
border-radius: 10px;
}

.fcs {
  position: relative; /* set parent container position to relative */
  display: flex;
  justify-content: center;
}

.fc{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.delimiter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 30px;
}

.mint {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.permissionicons {
  width: 5vw;
}

.approvalbar {
  width: 100%;
}

.quickAttack {
  width: 100%;
  justify-content: space-between;
}

.scoreAttackDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto;
  font-size: 4vh;
  font-family: 'mkMyth';
  justify-content: center;
  overflow: auto;
  max-height: 20vh;
}

.scoreText {
  font-size: 1vh;
  font-family: 'NexaHeavy';
}
.scoreText:hover {
  font-size: 2vh;
}

.vs{
  height: 85px;
  position: absolute; /* set middle image position to absolute */
  left: 50%; /* position in the middle */
  top: 50%;
  transform: translate(-50%, -50%); /* center the image */
  z-index: 2; /* set z-index value to 2 to place it on top of other images */
}

.form-container {
  display: none;
}

.form-container.show {
  display: block;
}

.form-container.hide {
  display: none;
}

.form-hint {
  font-size: 12px;
  color: gray;
}


.textstyle {
  text-decoration: none;
  text-align: center;
  font-size: 12px;
  font-family: 'NexaHeavy';
}

.fz {
  text-align: center;
  font-size: 30px;
  font-family: 'NexaHeavy';
}

.tgold {
  color: goldenrod;
}

.talmond {
  color: blanchedalmond;
}

.twhite {
  color: white;
}

.torange {
  color: orange;
}

.tlevel {
  color: rgb(170, 110, 19);
}

.twhitesmoke {
  color: whitesmoke;
}

.tblack {
  color: black;
}

.tblue {
  color: skyblue;
}

.tcyan {
  color: cyan;
}

.tmint {
  color: rgb(123, 106, 99);
}

.thatch {
  color: rgb(240, 240, 192);
}

.tred {
 color: red;
}

.tdebilitate {
  color: rgb(116, 171, 212);
}

.card {
  width: clamp(120px, 30vw, 200px);
  height: 200px; 
  padding: 2px, 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}


.nftImageMB {
  height: 10vh;
  border-radius: 5px;
  margin: 1vw;
  cursor: pointer;
}

.largeNFTmb {
  padding-top: 0.5vh;
  width: 100vw;
}

.revoke {
  position: absolute;
  align-items: center;
  border: 3px solid black;
  border-radius: 25px;
  bottom: 5%;
  min-width: 14%;
  max-width: 15%;
  right: 2%;
  min-height: 4%;
  max-height: 6%;
  color: black;
  font-size: 14px;
  font-family: 'NexaHeavy';
  background-color: gray;
  opacity: 0.9;
}

.approval {
  position: absolute;
  cursor: pointer;
  align-items: center;
  border: 3px solid wheat;
  border-radius: 25px;
  bottom: 9%;
  min-width: 14%;
  max-width: 15%;
  right: 8%;
  min-height: 4%;
  max-height: 6%;
  color: yellow;
  font-size: 14px;
  font-family: 'NexaHeavy';
  background-color: yellow;
}

.inputform {
background-color: black;
width: 15vw;
height: 5vh;
margin: 0.25vh;
border-radius: 25px;
text-align: center;
font-family: 'NexaHeavy';
font-size: 1.7vh;
}

.inputformMb {
  width: 50vw;
  background-color: black;
  height: 5vh;
  margin: 3px;
  border-radius: 25px;
  text-align: center;
  font-family: 'NexaHeavy';
  font-size: 2vh;
  }

.hidden {
  display: none;
}

.box {
  animation: fade 5s infinite;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.box1 {
  animation: fade 5s infinite;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.box2 {
  animation: fade 2s infinite;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.box3 {
  animation: fade 3s infinite;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.box4 {
  animation: fade 4s infinite;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}


.spinner {
  animation: spin 30s ease-in-out infinite;
  animation-delay: 40s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 50s;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(0deg); }
  30% { transform: rotate(360deg); }
  55% { transform: rotate(360deg); }
  60% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}

/* mobile */

.mobilelogo {
  padding: 2px;
  justify-content: center;
  width: 100vw;
  background-color: black;
  opacity: 0.7;
}

.mobileconnect {
  padding: 3px;
  /* color: yellow; */
  font-size: 20px;
  font-family:'NexaHeavy';
  justify-content: center;
  width: 100vw;
  background-color: black;
  opacity: 0.9;
  /* border: 1px solid yellow; */
}

.mobilebalances {
  padding: 10px;
  font-size: 15px;
  font-family:'EvilEmpire';
  justify-content: center;
  width: 100vw;
  background-color: black;
  opacity: 0.7;
}

.mobileye {
  display: flex;
  flex-direction: row;
  padding: 4px;
  justify-content: space-between;
  width: 100vw;
  opacity: 0.8;
}

.mobilenotify {
  color: white;
  font-size: 10px;
  font-family: "NexaHeavy";
  max-height: 10vh;
  width: 100vw;
  background-color: black;
  opacity: 0.95;
  align-items: center;
  overflow: auto;
}

.mobilecontent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobileapprove {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 3px;
  width: 40%;
  margin: auto;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.mobile-approval-limit{
  color: whitesmoke;
  font-size: 2.4vh;
  font-family:"NexaHeavy";
  align-items: center;
  justify-content: space-evenly;
  padding: 2px;
  width: 100vw;
  background-color: black;
  opacity: 0.65;
}

.wolfslashmb{
  font-family: 'EvilEmpire';
}

.nftdetails{
  margin-top: 2px;
  margin-bottom: 2px;
  height: 20%;
  width: 100%;
}

.nftnamer{
  font-size: 40px;
  min-height: 40px;
  max-height: 100%;
  width: 100%;
  text-align: left;
  opacity: 0.9;
  margin-left: 10px;
  font-family: 'mkMyth';
}

.notifyIconMobile{
  height: 50px;
  margin-top: 2px;
  margin-bottom: 1px;
}

.a-mobile{
  color: red;
  margin-top: 6px;
  margin-bottom: 2px;
  width: 100%; /* spans a third of the width of the page */
  background-image: linear-gradient(#000000, #E21143, #000000);
  cursor: initial;
}

.d-mobile {
  /* color: yellow; */
  margin-top: 6px;
  margin-bottom: 2px;
  width: 100%; /* spans a third of the width of the page */
  /* background-image: linear-gradient(black, yellow, black); */
  cursor: initial;
}

.vsmobile{
  height: 34px;
  position: absolute; /* set middle image position to absolute */
  left: 50%; /* position in the middle */
  top: 50%;
  transform: translate(-50%, -50%); /* center the image */
  z-index: 2; /* set z-index value to 2 to place it on top of other images */
}

.background-video {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video {
  position: absolute;
  width: 100vw;
  height: 100vw;
  z-index: -100;
}

.updatebox {
display: flex;
border: 2px solid black;
margin-bottom: 40px;
font-family: 'NexaHeavy';
font-size: 10px;
color: black;
cursor: pointer;
border-top-left-radius: 10px;
border-bottom-left-radius: 10px;
border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
width: 50%;
align-items: center;
justify-content: center;
}

.updateboxfalse {
  background-color: skyblue;  
  box-shadow: 0px 5px 30px rgba(135, 206, 235, 0.50);
}

.updateboxtrue {
  background-color: yellow;
}

.namebox {
  background-color: skyblue;
  margin: 4px;
  padding: 4px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.nameboxtrue{  
  color: black;
  background-color: gray;
  opacity: 0.4;
}

.imagebox {
  background-color: yellow;
  margin: 4px;
  padding: 4px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.imageboxfalse{  
  color: black;
  background-color: gray;
  opacity: 0.4;
}

.boxactive {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.50);
}

.fbfalse{
opacity: 0.4;
}

.fbtrue{
width: 100%;
}

.upnfont {
  display: flex;
  flex-direction: column;
  /* color:  yellow; */
  font-family: 'NexaHeavy';
  font-weight: bold;
  border-radius: 5px;
  opacity: 0.8;
  margin-top: 2px;
  text-shadow: 2px 2px 8px black;
}

.nameback {  
  /* background-color: yellow; */
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
  background-image: url(./assets/images/productlaunch.gif);
}

.updateinput {
  color: white;
  background-color: black;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  opacity: 0.75;
  margin: 4px;
}

.wait-cursor {
  cursor: wait;
}

.loadbearertrue {
  opacity: 0.15;
}

.blind-displaytrue {
 opacity: 0;
}

.lbtable1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Divide the grid into 3 equal columns */
  gap: 2px;
  width: 80%;
  margin: 12vh 0vw 0vh 0vw;
  overflow-y: auto;
  overflow-x: hidden;
  /* margin: 0.5vh; */
}

.lbtable1Mb {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Divide the grid into 8 equal columns */
  gap: 3px; /* Add some gap between cells */
  width: 100%;
  height: auto;
  overflow: hidden;
  /* margin: 0.5vh; */
}

.pagemarker {
  position: fixed;
  top: 20vh;
  right: 22vw;
  height: 80vh;
  width: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.pagemarkerMB {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0.2vw 0.1vw 0.2vh 0.1vw;
  overflow-y: auto; 
}

.lbtable2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; /* Divide the grid into 4 equal columns */
  gap: 4px; 
  /* overflow: hidden; */
  /* margin: 0.5vh; */
}

.lbtitles {
  overflow-y: auto;
  height: 75vh;
}

.lbtitle {
  display: flex;
  font-family: 'NexaHeavy';
  font-size: 8px;
  color: violet;
  background-color: black;
  width: 25%;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  align-content: center;
  justify-content: center;
  opacity: 0.75;
}

.leaderboard {
  font-family: 'NexaHeavy';
  font-size: small;
  background-color: black;
  width: 10vw;
  text-align: center;
  align-content: center;
  justify-content: space-between;
  opacity: 0.75;
  border-radius: 15px;
  margin: 0vh 0.25vw 0vh 0.25vw;
  padding: 0vh 2.5vw 0vh 2.5vw;
  cursor: pointer;
}

.leaderboardMb {
  font-family: 'NexaHeavy';
  background-color: black;
  width: 30vw;
  text-align: center;
  align-content: center;
  justify-content: space-between;
  opacity: 0.75;
  border-radius: 15px;
  cursor: pointer;
}

.lbLarge {  
  font-size: 8vh;
  padding: 0.5vh;
}

.lbLargeMb {  
  font-size: 2.5vh;
  padding: 0.5vh;
  margin: 0.5vh;
}

.lbMid {  
  font-size: 6vh;
  padding: 0.5vh;
}

.lbMidtxt {  
  font-size: 4vh;
  padding: 0.5vh;
  margin: 0.25vh;
}

.lbSmall {  
  font-size: 2vh;
  padding: 0.5vh;
  margin: 0.25vh;
}

.lbSmallMb {  
  font-size: 1.5vh;
  padding: 0.25vh;
  margin: 0.25vh;
}

.lbMobile {
 padding: 0.5vh;
 border: none;
}

.jlb {
  justify-content: center;
}

.lbAcolor {
  color: lime;
}

.aplist{
  position: absolute;
  top: 17.5vh;
  margin: 0vh 0vw 0vh 0.3vw;
  padding: 0vh 0.25vw 1vh 0vw; 
  width: 6vw;
  height: 8vh;
  border: 0.3vh red solid;
  background-color: black;
  border-radius: 10px;
  color: goldenrod;
  text-align: right;
  font-size: 40px;
  font-family: 'RealVirtue';
  cursor: pointer;
  opacity: 0.8;
}

.aplistmb{
  color: goldenrod;
  font-family: 'EvilEmpire';
}

.activityboard {
  position: absolute; /* this positions the division beneath the header */
  top: 45px; /* positions the division 50px beneath the header */
  left: 16%; /* aligns the division to the right of the page */
  width: 75%; /* spans a third of the width of the page */
  height: calc(100vh - 47px); /* spans the remaining length of the page */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
}

.dropNetwork {
  width: 4vw;
  height: 4vw;
  opacity: 0.7;
  border-radius: 250px;
  justify-content: center; 
  padding: 1vh;
  margin: 2vh;
  cursor: pointer;
}

.dropNetworkmb{
  width: 6vh;
  height: 6vh;
  opacity: 0.7;
  border-radius: 250px;
  justify-content: center; 
  padding: 1vh;
  margin: 2vh;
  cursor: pointer;
}

.plusNetwork{
  width: 4vw;
  height: 4vw;
  opacity: 0.7;
  border-radius: 250px;
  justify-content: center; 
  padding: 1vh;
  margin: 2vh;
  cursor: pointer;
}

.plusNetworkmb{
  width: 6vh;
  height: 6vh;
  opacity: 0.7;
  border-radius: 250px;
  justify-content: center; 
  padding: 1vh;
  margin: 2vh;
  cursor: pointer;
}

.on18{
  opacity: 0.1;
  cursor: no-drop;
}

.Networklogo {
  width: 6vw;
  height: 6vw;
  opacity: 0.8;
}

.Networklogomb {
  width: 8vh;
  height: 8vh;
  opacity: 0.8;
  margin: 1vh;
}

.leader-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 23vh;
  left: 25vw;
  background-color: white;
  max-height: 35vh;
  font-family: 'NexaHeavy';
  opacity: 0.9;
  border-radius: 20px;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.little-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: black;
  transform: translate(-50%, -50%);
  font-family: 'NexaHeavy';
  opacity: 0.9;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.leader-column {
  display: flex;
  max-height: 35vh;
  background-size: cover;
  background-position: center;
  font-family: 'NexaHeavy';
  opacity: 0.9;
  border-radius: 20px;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.leader-return-text{ 
  color: white; 
  font-size: 3.5vh;
  /* padding: 0.5vh; */
  /* margin: 1vh 0vh 0vh 0vh; */
  cursor: pointer;
  opacity: 0.7;
}

.little-return-text{ 
  color: white; 
  font-size: 3.5vh;
  /* padding: 0.5vh; */
  /* margin: 1vh 0vh 0vh 0vh; */
  cursor: pointer;
  opacity: 0.7;
}

.player-info {
  display: flex;
  flex-direction: column;
  height: 30.5vh;
}

.player-info-mb {
  display: flex;
  flex-direction: column;
}

.leadhouse-info {
  display: flex;
  flex-direction: column;
  width: 30vw;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1vh solid gray; 
  padding: 2vh;
  background-color: #ccc;
  opacity: 0.9;
}

.leadhouse-info-mb {
  display: flex;
  flex-direction: column;
  width: 60vw;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1vh solid gray; 
  padding: 1vh;
  background-color: #ccc;
  opacity: 0.8
}

.left-right {
  border-top-left-radius: 4vh;
  border-bottom-right-radius: 4vh;
}

.right-left {
  border-top-right-radius: 4vh;
  border-bottom-left-radius: 4vh;
}

.profile-pic {
  display: flex;
  height: 35vh;
  background-size: cover;
  background-position: center;
}

.profile-pic-mb {
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  /* border: 1px solid red; */
}

.player-profile-pic {
  height: 30vh;
  width: 30vh;
  margin-right: 0.75vh;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  cursor: pointer;
}

.player-profile-pic-mb {
  width: 55vw;
  height: 55vw;
  border-radius: 20px;
  cursor: pointer;
}

.blank-profile-pic {
  height: 29vh;
  width: 29vh;
  margin-right: 0.75vh;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  opacity: 0.95;
  background-size: cover;
  background-position: center;
  background-image: url(./assets/images/icons/cosmos-universo.gif);
  cursor: pointer;
}

.blank-profile-pic-mb {
  width: 55vw;
  height: 55vw;
  border-radius: 20px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-image: url(./assets/images/icons/cosmos-universo.gif);
  /* border: 1px solid gold; */
}

.player-details {
  margin: 0.1vh;
  max-height: 35vh;
  /* border: 1px solid gold; */
}

.scoreassaulttrue{
  opacity: 0.2;
  cursor: not-allowed;
}

.scoredebilitatetrue{
  opacity: 0.2;
  cursor: not-allowed;
}

.player-details-mb {
  display: flex;
  flex-direction: column;
  /* padding: 1vh; */
  /* border: 1px solid blue; */
}

.player-details-namebox {
  position: absolute;
  left: 11%;
  opacity: 0.85;
  border-radius: 8px;
}

.player-details-namebox-mb {
  position: absolute;
  top: 1vh;
  right: 18%;
  /* border: 1px solid skyblue; */
  opacity: 0.85;
  border-radius: 8px;
}

.player-details-idbox {
  position: absolute;
  bottom: 0px;
  left: 11%;
  /* border: 1px solid skyblue; */
  opacity: 0.85;
  border-radius: 8px;
}

.player-details-idbox-mb {
  /* border: 1px solid skyblue; */
  opacity: 0.5;
  border-radius: 8px;
}

.player-details-box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border: 1px solid gold;
  background-color: black;
  border-radius: 5px;
  opacity: 0.85;
}

.player-details-box-mb {
  display: flex;
  width: 15vw;
  margin: 0.5vw;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid gold;
  background-color: black;
  border-radius: 5px;
  opacity: 0.85;
}

.player-details-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70%;
  border: 1px solid purple;
}

.col-1 {
  background-image: url(./assets/images/batdog.jpg);
}

.col-2 {
  background-image: url(./assets/images/superdog.jpg);
}

.leader-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.leader-form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;    
  width: 50%;
}

.leader-form button {
  width: 100%;
  padding: 8px;
  background-color: limegreen;
  color: white;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;    
  width: 25%;
}

.leader-button {
  width: 100%;
  padding: 8px;
  background-color: limegreen;
  color: white;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  width: 50%;
}

.leader-form button:hover {
  background-color: limegreen;
}

.react-colorful {
  width: 200px !important;
  height: 200px !important;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}